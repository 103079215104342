import Navbar from "../components/Navbar"
import Button from "../components/Button"
import { login, logout } from "../utils/auth"

const Home = ({user}) => {
    return (
        <div className="edds-body">
            <Navbar>
                <Button buttonText="Sign In" primary={false} onClick={() => login()}></Button>
            </Navbar>
            <div className="edds-content">
                <h1>Erkennst du den Song?</h1>
                <p className="edds-intro">
                    In dieser musikalischen Quizshow geht es darum, anhand eines Songausschnitts schneller als die sowohl den Namen als auch die Interpreten des Songs zu nennen! Verbinde dein Spotify, um zu spielen!
                </p>
                <Button buttonText="Sign In" primary={true} onClick={() => login()}></Button>
            </div>
        </div>
    )
}

export default Home
