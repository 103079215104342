import { login, logout } from "../utils/auth"
import Button from "../components/Button"
import Navbar from "../components/Navbar"


const Winscreen = ({user}) => {
    return (
        <div className="edds-body">
            <Navbar>
                <p>Hey, {user.display_name}</p>
                <Button buttonText="Sign Out" primary={false} onClick={() => logout()}></Button>
            </Navbar>
            <div className="edds-content">
                <h1>Winner, Winner, Chick Dinner!</h1>
                <p>
                </p>
                <Button buttonText="Sign In" primary={true} onClick={() => login()}></Button>
            </div>
        </div>
    )
}

export default Winscreen
