const TableItem = ({playerName, buzzer, removeHandler}) => {

    return (

        <tr>
            <td className="edds-playerlist-control" onClick={removeHandler}>-</td>
            <td className="edds-playerlist-playername">{playerName}</td>
            <td className="edds-playerlist-buzzerid">{buzzer}</td>
        </tr>       
    );
}

export default TableItem;