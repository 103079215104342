import Navbar from "./components/Navbar";
import "./App.css";
import Home from "./pages/Home";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Matchsettings from "./pages/Matchsettings";
import Gamescreen from "./pages/Gamescreen";
import Winscreen from "./pages/Winscreen";

import { gql, useSubscription, useMutation } from '@apollo/client'; 

const START_GAME = gql`
mutation Mutation($players: [PlayerInput], $device_id: String, $playset: String) {
  startGame(players: $players, device_id: $device_id, playset: $playset)
}
`

const CHANGED_GAME_STATE_SUBSCRIPTION = gql`
subscription Subscription {
  changedGameState 
}
`

const App = () => {
  const [user, setUser] = useState(null);
  const [deviceID, setDeviceID] = useState(null)
  const [player, setPlayer] = useState(undefined)
  const [playerReady, setPlayerReady] = useState(false)
  // const [playing, setPlaying] = useState("NOTHING")

  const [startGame, {data: gameData, loading: gameLoading, error: gameError}] = useMutation(START_GAME)

  const {loading: changedGameStateLoading, error: changedGameStateError, data: playing} = useSubscription(CHANGED_GAME_STATE_SUBSCRIPTION)

  if(changedGameStateError) 
    console.log(changedGameStateError)

  useEffect(() => {
    const getUser = () => {
      fetch("/auth/login/success", {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          throw new Error("authentication has been failed!");
        })
        .then((resObject) => {
          console.log("Valid Authentication")
          setUser(resObject.user);
          startSpotify(resObject.user);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getUser();
  }, []);

  const startSpotify = (u) => {
    if(!playerReady) {

      const script = document.createElement("script");
      script.src = "https://sdk.scdn.co/spotify-player.js";
      script.async = true;

      document.body.appendChild(script);

      window.onSpotifyWebPlaybackSDKReady = () => {

          const player = new window.Spotify.Player({
              name: 'EDDS Player',
              getOAuthToken: cb => { cb(u.accessToken); },
              volume: 0.5
          });

          setPlayer(player);

          player.addListener('ready', ({ device_id }) => {
              console.log('Ready with Device ID', device_id);
              setDeviceID(device_id);
              setPlayerReady(true);
              
              //player.resume()
          });

          player.addListener('not_ready', ({ device_id }) => {
              console.log('Device ID has gone offline', device_id);
              setPlayerReady(false);
          });

          console.log("Connecting Spotify Player...")

          player.connect();
      };
    }
  };

  useEffect(() => () => {
    console.log("Unmounting App...")
    setPlayerReady(false);
    if(player) {
      console.log("Disconnecting Spotify...")
      player.disconnect()
      setPlayer(undefined)
    }
  }, [])

  const handleGamestart = (players, selection = "v0.3") => {
    if(playerReady) {
      console.log('Started with playlist ' + selection)
      startGame({ variables: { players: players, device_id: deviceID, playset: selection }})
        .then((response) => {
          console.log('Game was successfully started', response);
        })
        .catch((error) => {
          console.error('Starting Game failed!', error);
        });
    } else {
      console.log("Can't start Match because Spotify Playback isn't ready.")
    }
  };

  const resolvePage = () => {
    
      switch(playing) {
        case 'PLAYING': return <Gamescreen user={user} start={handleGamestart} />;
        case 'COMPLETED': return <Winscreen user={user} />;
        default: return <Matchsettings user={user} />;
      }
    
  }

  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/" element={!user ? <Home user={user} /> : <Navigate to="/play"/>} />
          {/* <Route path="/play" element={user ? (() => {
              switch(playing) {
                case 'PLAYING': return <Gamescreen user={user} start={handleGamestart} />;
                case 'COMPLETED': return <Winscreen user={user} />;
                default: return <Matchsettings user={user} />;
              }}) : <Navigate to="/"/>}/> */}
             <Route path="/play" element={user ? (playing ? ( playing.changedGameState === "RUNNING" ?  <Gamescreen user={user}  /> : (playing.changedGameState === 'COMPLETED' ? <Winscreen user={user} />  :  <Matchsettings user={user} start={handleGamestart}/> )) : <Matchsettings user={user} start={handleGamestart}/> ): <Navigate to="/"/>}/>

          {/* <Route path="/matchsettings" element={user ? <Matchsettings/> : <Navigate to="/"/>}/> */}
          {/* <Route path="/gamescreen" element={user ? (playing === "PLAYING" ? <Gamescreen/> : <Navigate to="/matchsettings"/>): <Navigate to="/"/>}/> */}
          {/* <Route path="/winscreen" element={user ? (playing === "COMPLETED" ? <Gamescreen/> : <Navigate to="/matchsettings"/>): <Navigate to="/"/>}/> */}
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;