import { useQuery, gql, useSubscription } from '@apollo/client'; 
import { useEffect } from 'react';

const PLAYER_SCORED_SUBSCRIPTION = gql`
    subscription OnPlayerScored {
        playerScored {
            id
            points
        }    
    }
    
`;

const PLAYER_BUZZERED_SUBSCRIPTION = gql`
    subscription OnPlayerBuzzered {
        playerBuzzered
    }
`

const PLAYERS_QUERY = gql`
    query {
        players {
            id
            name
            points
        }
    }
`

const PlayerFooter = ({changedTrackStateData}) => {
    const playerScoredError = false
    const { loading: playerBuzzeredLoading, error: playerBuzzeredError, data: playerBuzzered} = useSubscription(PLAYER_BUZZERED_SUBSCRIPTION)
    const { loading: playersLoading, error: playersError, data: playersData, subscribeToMore} = useQuery(PLAYERS_QUERY)


  //  const playerScored = playerScoredData;
    // const [players] = playersData.players;
    const players = [];
    console.log(playersData)
    if(playersError)
        console.log(playersError)
    

    useEffect(() => 
        subscribeToMore({
            document: PLAYER_SCORED_SUBSCRIPTION,
            updateQuery: (prev, { subscriptionData }) => {
                if(!subscriptionData.data) return prev;

                const newScoreItem = subscriptionData.data.playerScored;
                // return Object.assign({}, prev, {
                //     post: {
                //         players: [newScoreItem, ...prev.post.players]
                //     }
                // })
                console.log(newScoreItem)
                console.log(prev)
                prev.players.find(x => x.id == newScoreItem.id).points = newScoreItem.points;
                return prev;
            }
        }), 
    [])

    console.log(`${changedTrackStateData?.changedTrackState} while player ${playerBuzzered} buzzered `)

    return (
        <div className='edds-footer'>
            {
                playerScoredError || playersError ? <p>Can't load Players</p>: (
                playersLoading ? <p>Lade die Spieler...</p> :
                (playersData.players.map((player, index) => { 
                    return (
                    <div key= {index} className={changedTrackStateData?.changedTrackState === "PAUSED" && playerBuzzered?.playerBuzzered == player.id ? 'edds-player edds-player-buzzered' : 'edds-player'}>
                        <p className='edds-footer-score'>{player.points}</p>
                        <p className='edds-footer-playername'>{player.name}</p>
                    </div>
                    )
                    }
                ))
                )
            }
        </div>
    )
}

export default PlayerFooter;