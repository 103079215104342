import { Link } from "react-router-dom";

const Navbar = (props) => {
  return (
    <div className="edds-navbar">
      <span className="edds-logo">
        <Link className="link" to="/">
          EDDS
        </Link>
      </span>
      <div className="edds-navbar-items">
        {props.children}
      </div>
    </div>
  );
};

export default Navbar;