import { gql, useSubscription, useMutation } from '@apollo/client'; 
import { useEffect, useState } from 'react';
import { AiOutlineCheckCircle, AiOutlineCloseCircle, AiOutlinePlayCircle, AiOutlineRightCircle } from 'react-icons/ai'

const PLAYING_TRACK_SUBSCRIPTION = gql`
    subscription Subscription{
        playingTrack {
            id
            name
            artists
            duration_ms
            cover
          }
    }
`

const TRACK_TIMER_SUBSCRIPTION = gql`
    subscription Subsription{
        trackTimer
    }
`



const INCREMENT_SCORE = gql`
    mutation IncrementScore {
        addScore 
    }
`

const PLAY_TRACK = gql`
    mutation PlayTrack {
        playTrack
    }
    
`

const LOCK_PLAYER = gql`
    mutation PlayTrack {
        lockPlayer
    }
    
`

const NEXT_TRACK = gql`
    mutation NextTrack {
        nextTrack
    }
    
`

const SongCard = ({ user, changedTrackStateData, changedTrackStateError, changedTrackStateLoading }) => {

    const [playTrack, {data: playTrackData, loading: playTrackLoading, error: playTrackError}] = useMutation(PLAY_TRACK)
    const [addScore, {data: addScoreData, loading: addScoreLoading, error: addScoreError}] = useMutation(INCREMENT_SCORE)
    const [lockPlayer, {data: lockPlayerData, loading: lockPlayerLoading, error: lockPlayerError}] = useMutation(LOCK_PLAYER)
    const [nextTrack, {data: nextTrackData, loading: nextTrackLoading, error: nextTrackError}] = useMutation(NEXT_TRACK)



    const {loading: playingTrackLoading, error: playingTrackError, data: playingTrackData} = useSubscription(PLAYING_TRACK_SUBSCRIPTION)
    const {loading: trackTimerLoading, error: trackTimerError, data: trackTimerData} = useSubscription(TRACK_TIMER_SUBSCRIPTION)


    console.log(changedTrackStateData)
    console.log(playingTrackData)
    console.log(trackTimerData)


    const handleCorrectClick = () => {
        addScore()
    }    

    const handleResumeClick = () => {
        playTrack()
    }
    
    const handleWrongClick = () => {
        lockPlayer()
    }    

    const handleSkipClick = () => {
        nextTrack()
    }

    if(playingTrackLoading || changedTrackStateLoading ) 
        return <p>Loading...</p>

    if(playingTrackError) {
        console.log(playingTrackError)
        return <p>Playing Track Subscription Error</p> 
    } else if(changedTrackStateError) {
        console.log(changedTrackStateError)
        return <p>Changed Track Subscription Error</p>
    } else if(trackTimerError) {
        console.log(trackTimerError)
        return <p>Track Timer Error</p>
    }

    const disabled = () => {
        console.log(changedTrackStateData.changedTrackState)
        if(changedTrackStateData.changedTrackState === 'PLAYING') {
            return true
        } else if(changedTrackStateData.changedTrackState === 'PAUSED') {
            return false
        }
    }
    

    return (
        <div className='edds-song-card'>
            <div className='edds-song-cover' style={{backgroundImage: `url(${playingTrackData.playingTrack.cover}`}}>
                {/* <img src={playingTrackData.playingTrack.cover} alt='Album Cover'/> */}
            </div>
            <div className='edds-song-details'>
                <p className='primary-text'>{playingTrackData.playingTrack.name}</p>
                <p className='secondary-text'>{playingTrackData.playingTrack.artists.join(', ')}</p>
                <p className='secondary-text'>{trackTimerData?.trackTimer}</p>
            </div>
            {changedTrackStateData.changedTrackState === 'PLAYING' ? <div className='edds-song-controls'><AiOutlineRightCircle onClick={handleSkipClick}/></div> :
            <div className='edds-song-controls'>
                <AiOutlineCloseCircle onClick={handleWrongClick}/>
                <AiOutlinePlayCircle onClick={handleResumeClick}/>
                <AiOutlineCheckCircle onClick={handleCorrectClick}/>
                {/* <ImageButton src='client\edds\src\res\icons\x_icon.png' onClick={handleWrongClick} disabled={disabled}/> */}
                {/* <ImageButton src='client\edds\src\res\icons\play_icon.png' onClick={handleResumeClick} disabled={disabled}/> */}
                {/* <ImageButton src='client\edds\src\res\icons\haekchen_icon.png' onClick={handleCorrectClick} disabled={disabled}/> */}
            </div>
            }   
        </div>
    )
}

export default SongCard;