import { useState } from "react";
import TableItem from "./TableItem";

const PlayersList = ({listState, setListState}) => {
    // const [listState, setListState] = useState();
    const [nameState, setNameState] = useState("");
    const [buzzerIDState, setBuzzerIDState] = useState("");


    const handlePlusClick = () => {
        if((nameState != null || nameState.trim() != "" ) && (buzzerIDState != null || buzzerIDState != "")) {
            const Player = {
                name: nameState,
                buzzerUUID: buzzerIDState,
            }
            setNameState("")
            setBuzzerIDState("")
            setListState((prevListState) => [...prevListState, Player])
        }
    }
    
    return (
        <table className="edds-playerlist">
            <tbody>
                {
                    listState.map((item, idx) => {return <TableItem key={idx} playerName={item.name} buzzer={item.buzzerUUID} removeHandler={() => setListState(listState.filter(e => e !== item))}/>})
                }
                <tr>
                <td className="edds-playerlist-control" onClick = {handlePlusClick}>+</td>
                <td className="edds-playerlist-playername"><input type="text" placeholder="Spieler" value={nameState} onChange={(e) => setNameState(e.target.value)}/></td>
                <td className="edds-playerlist-buzzerid"><input type="text" placeholder="BuzzerID" value={buzzerIDState} onChange={(e) => setBuzzerIDState(e.target.value)}/></td>
                </tr>
        </tbody>
        </table>
    )
}

export default PlayersList;