import Navbar from "../components/Navbar"
import SongCard from "../components/SongCard"
import { logout } from "../utils/auth"
import Button from "../components/Button"
import { useEffect } from "react"
import { gql, useMutation, useSubscription } from '@apollo/client'; 
import PlayersFooter from "../components/PlayerFooter"


const PLAY_TRACK = gql`
mutation Mutation {
    playTrack
  }
`

const CHANGED_TRACK_STATE_SUBSCRIPTION = gql`
    subscription Subscription {
        changedTrackState
    }
`

const Gamescreen = ({user}) => {

    const [playTrack] = useMutation(PLAY_TRACK)
    const {loading: changedTrackStateLoading, error: changedTrackStateError, data: changedTrackStateData} = useSubscription(CHANGED_TRACK_STATE_SUBSCRIPTION)


    useEffect(() => {
        // Prevents running twice when in React.StrictMode
        if (typeof window !== 'undefined') {
            console.log("Requesting Start of Game")
            playTrack()
        }
    }, [])

    return (
        <div className="edds-body">
            <Navbar>
                <p>Hey, {user.display_name}</p>
                <Button buttonText="Abort" primary={false} onClick={() => logout()}></Button>
            </Navbar>
            <div className="edds-content">
                <SongCard user={user} changedTrackStateData={changedTrackStateData} changedTrackStateError={changedTrackStateError} changedTrackStateLoading={changedTrackStateLoading}/>
            </div>
            <PlayersFooter changedTrackStateData={changedTrackStateData}/>
        </div>
    )
}

export default Gamescreen
