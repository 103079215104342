import Navbar from "../components/Navbar"
import Button from "../components/Button"
import { login, logout } from "../utils/auth"
import { useState } from "react"
import PlayerList from "../components/PlayersList"
import { useQuery, gql } from "@apollo/client"

const PLAYSETS = gql`
query Playsets {
    playsets
}

`


const Matchsettings = ({ start, user }) => {

    const {loading, error, data} = useQuery(PLAYSETS)

    const [listState, setListState] = useState([]);
    const [selection, setSelection] = useState("")

    console.log(data)

    return (
        <div className="edds-body">
            <Navbar>
                <p>Hey, {user.display_name}</p>
                <Button buttonText="Sign Out" primary={false} onClick={() => logout()}></Button>
            </Navbar>
            <div className="edds-content">
                <h1>Ready, Set, Go!</h1>
                <div className="edds-matchsettings">
                    <PlayerList listState={listState} setListState={setListState}/>
                </div>
                <div className="edds-select edds-matchsettings-playset">
                <select value={selection} onChange={(i) => { console.log(i.target); setSelection(i.target.value)}}>
                    {
                        data?.playsets.map((el) => {
                            return <option value={el}>{el}</option>
                        })
                    }
                </select>
                </div>
                <Button buttonText="Start Match" primary={true} onClick={() => start(listState, selection)}></Button>
            </div>
        </div>
    )
}

export default Matchsettings
